<template>
  <div class="box-container">
    <div class="pc">
      <div>
        <h3 class="notmobile">{{ $t("index.advantages0Name") }}</h3>
        <img src="/img/save-time.svg" width="160px" alt="" />
        <p style="color:rgba(140, 145, 175, 1) ;">{{ $t("index.advantages0Descr") }}</p>
      </div>

      <div>
        <h3 class="notmobile">{{ $t("index.advantages1Name") }}</h3>
        <img src="/img/save-money.svg" width="160px" alt="" />
        <p style="color:rgba(140, 145, 175, 1) ;">{{ $t("index.advantages1Descr") }}</p>
      </div>

      <div>
        <h3 class="notmobile">{{ $t("index.advantages2Name") }}</h3>
        <img src="/img/anonymity.svg" width="160px" alt="" />
        <p style="color:rgba(140, 145, 175, 1) ;">{{ $t("index.advantages2Descr") }}</p>
      </div>
    </div>
    <div class="mobile">
      <div>
        <img src="/img/save-time.svg" width="64px" alt="" />
        <div>
          <h3>{{ $t("index.advantages0Name") }}</h3>
          <p style="color:rgba(140, 145, 175, 1) ;">{{ $t("index.advantages0Descr") }}</p>
        </div>
      </div>

      <div>
        <img src="/img/save-money.svg" width="64px" alt="" />

        <div>
          <h3>{{ $t("index.advantages1Name") }}</h3>
          <p style="color:rgba(140, 145, 175, 1) ;">{{ $t("index.advantages1Descr") }}</p>
        </div>
      </div>

      <div>
        <img src="/img/anonymity.svg" width="64px" alt="" />
        <div>
          <h3>{{ $t("index.advantages2Name") }}</h3>
          <p style="color:rgba(140, 145, 175, 1) ;">{{ $t("index.advantages2Descr") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less">
.box-container {
  /* border: 1px solid red; */
  color: white;
}
@font-face {
  font-family: 'Bebas';
  src: url('/src/assets/font/BebasNeue-Regular.ttf');
  
}

/** 移动端 */
@media screen and (max-width: 768px) {
  .pc {
    display: none;
  }
  .mobile {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
    > div {
      margin-top: 10px;
      > img {
        margin: 10px;
      }

      > div {
        > h3 {
          font-family: Bebas;
          font-size: 16px;
          font-weight: 400;
          line-height: 21.17px;
          color: #ffffff;
        }
      }
      display: flex;
      align-items: center;
    }
  }
}

/** pc 端 */
@media screen and (min-width: 768px) {
  .pc {
    display: flex;
    width: 80%;
    margin: 100px auto 0;
    // border: 1px solid red;
    max-width: 1200px;
    > div {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      > h3 {
        color: #ffffff;
        font-family: Bebas;
        font-size: 20px;
        font-weight: 400;
        line-height: 26.46px;
        text-align: center;
      }
      > p {
        
        font-size: 16px;
        font-weight: 400;
        line-height: 19.3px;
        text-align: center;
        color: #8c91af;
        max-width: 240px;
      }
    }
  }
  .mobile {
    display: none;
  }
}
</style>
