<template>
  <div class="container">
    <!-- pc端 -->
    <div class="pc-box box-container">
      <!-- 左侧交换框 -->
      <div class="input-container">
        <div class="rate">
          1 {{ leftCoin.sortName }} ≈ {{ exchangePrice.from.rate }}
          {{ rightCoin.sortName }}
        </div>

        <!-- 币输入框 第一个 -->
        <div class="col" ref="leftCoinArea">
          <div
            class="input-wabbr toright locked"
            :data-value="leftCoin.iconName"
          >
            <div
              class="ui-select-outer with-search hidden-label"
              :class="{
                active: showLeftSelect,
              }"
              :data-value="leftCoin.iconName"
            >
              <label class="ui-select" :data-value="leftCoin.iconName">
                <div class="ui-select-value">
                  <div
                    class="coin-ico svgcoin"
                    :class="leftCoin.iconName"
                  ></div>
                  <div class="coin-outer">
                    <span class="coin-name">{{ leftCoin.name }}</span>
                  </div>
                </div>
                <div class="ui-select-arrow"></div>
                <div class="ui-select-search">
                  <span class="ui-select-search-ico ico"></span>
                  <div class="ui-select-search-inner">
                    <input
                      type="text"
                      placeholder="Type a currency"
                      v-model="searchText"
                    />
                  </div>
                </div>
              </label>

              <div class="ui-select-list my-ui-list">
                <ul style="padding-right: 0px" class="self-scorll">
                  <li class="ui-select-separate">Popular currencies</li>
                  <li
                    class="ui-select-option hover"
                    v-for="item in currencyListPopularSearch"
                    :key="item[1]"
                    @click="chooseCoin('leftCoin', item, 'popular')"
                    :data-value="item[2]"
                  >
                    <div class="coin-ico svgcoin" :class="item[2]"></div>
                    <div class="coin-outer">
                      <span class="coin-name"
                        >{{ item[0] }} ({{ item[1] }})</span
                      >
                    </div>
                  </li>
                  <li class="ui-select-separate">All currencies</li>
                  <li
                    class="ui-select-option hover"
                    v-for="item in currencyListCoinSearch"
                    :key="item[1] + 'coin'"
                    @click="chooseCoin('leftCoin', item, 'coin')"
                    :data-value="item[2]"
                  >
                    <div class="coin-ico svgcoin" :class="item[2]"></div>
                    <div class="coin-outer">
                      <span class="coin-name"
                        >{{ item[0] }} ({{ item[1] }})</span
                      >
                    </div>
                  </li>
                  <li
                    class="ui-select-option hover"
                    v-for="item in currencyListTokenSearch"
                    @click="chooseCoin('leftCoin', item, 'token')"
                    :key="item[1] + 'token'"
                    :data-value="item[2]"
                  >
                    <div class="coin-ico svgcoin" :class="item[2]"></div>
                    <div class="coin-outer">
                      <span class="coin-name"
                        >{{ item[0] }} ({{ item[1] }})</span
                      >
                    </div>
                  </li>
                </ul>
                <div class="ui-select-scroll-wrap hidden" data-height="100">
                  <div
                    class="ui-select-scroll"
                    style="top: 0px; height: 100%"
                  ></div>
                </div>
              </div>
            </div>

            <select id="select_currency_from" class="hidden">
              <option label="separate">Popular currencies</option>
              <option selected="" value="btc" data-symbol="BTC" data-sub="">
                Bitcoin
              </option>
            </select>
            <input
              class="input-amount"
              type="text"
              value=""
              id="select_amount_from"
              data-dir="from"
              maxlength="18"
              v-model.number="formData.sendNum"
              @change="changeSendNum"
            />
            <div id="select_maxmin_from" class="hint maxmin">
              <span>min:</span>&nbsp;<button
                @click="
                  () => {
                    formData.sendNum = exchangePrice.from.min;
                    getExchangePrice();
                  }
                "
                type="button"
                class="maxmin-value"
              >
                {{ exchangePrice.from.min }} {{ leftCoin.sortName }}
              </button>
            </div>
            <span id="select_hinterror_from" class="hint error"></span>
            <label
              id="select_label_from"
              @click.stop="
                () => {
                  showLeftSelect = true;
                  showRightSelect = false;
                }
              "
            >
              <span
                style="margin-right: 0.3em"
                class="coin-img svgcoin"
                :class="leftCoin.iconName"
              ></span>

              <span v-if="leftCoin.sortName.indexOf('-') > -1"
                >{{ leftCoin.sortName.split("-")[0] }}
                <span>
                  ({{ leftCoin.sortName.split("-")[1] }})
                  <!-- <sup></sup> -->
                </span>
              </span>
              <span v-else>{{ leftCoin.sortName }}</span>

              <!-- <span class="my-border"></span> -->
            </label>
            <div class="input-rate rev" id="select_info_from" v-show="false">
              <button
                class="btn-text tolightning"
                type="button"
                data-dir="from"
              >
                <span class="ico lightning" data-value="btcln"></span>
                <span>Try lightning!</span>
              </button>
            </div>
            <!-- <div class="input-rate" id="select_rate_from">
              1 {{ leftCoin.sortName }} ≈ {{ exchangePrice.from.rate }}
              {{ rightCoin.sortName }}
            </div> -->
          </div>
        </div>

        <!-- 交换框按钮 -->
        <div class="change-btn" @click.prevent="exchangeCoin">
          <img width="20px" height="20px" src="/img/change-btn.svg" alt="" />
        </div>

        <div class="rate">
          1 {{ rightCoin.sortName }} ≈ {{ exchangePrice.to.rate }}
          {{ leftCoin.sortName }}
        </div>

        <!-- 币输入框 第二个 -->
        <div class="col" ref="rightCoinArea">
          <!-- <header>{{ $t("index.RECEIVE") }}</header> -->
          <div class="input-wabbr toright" :data-value="rightCoin.iconName">
            <div
              class="ui-select-outer with-search hidden-label"
              :class="{
                active: showRightSelect,
              }"
              :data-value="rightCoin.iconName"
            >
              <label class="ui-select" :data-value="rightCoin.iconName">
                <div class="ui-select-value">
                  <div class="coin-ico svgcoin eth"></div>
                  <div class="coin-outer">
                    <span class="coin-name">Ethereum</span>
                  </div>
                </div>
                <div class="ui-select-arrow"></div>
                <div class="ui-select-search">
                  <span class="ui-select-search-ico ico"></span>
                  <div class="ui-select-search-inner">
                    <input
                      type="text"
                      placeholder="Type a currency"
                      v-model="searchText"
                    />
                  </div>
                </div>
              </label>
              <div class="ui-select-list my-ui-list">
                <ul style="padding-right: 0px" class="self-scorll">
                  <li class="ui-select-separate">Popular currencies</li>
                  <li
                    class="ui-select-option hover"
                    v-for="item in currencyListPopularSearch"
                    :key="item[1]"
                    @click="chooseCoin('rightCoin', item, 'popular')"
                    :data-value="item[2]"
                  >
                    <div class="coin-ico svgcoin" :class="item[2]"></div>
                    <div class="coin-outer">
                      <span class="coin-name"
                        >{{ item[0] }} ({{ item[1] }})</span
                      >
                    </div>
                  </li>
                  <li class="ui-select-separate">All currencies</li>
                  <li
                    class="ui-select-option hover"
                    v-for="item in currencyListCoinSearch"
                    :key="item[1] + 'coin'"
                    @click="chooseCoin('rightCoin', item, 'coin')"
                    :data-value="item[2]"
                  >
                    <div class="coin-ico svgcoin" :class="item[2]"></div>
                    <div class="coin-outer">
                      <span class="coin-name"
                        >{{ item[0] }} ({{ item[1] }})</span
                      >
                    </div>
                  </li>
                  <li
                    class="ui-select-option hover"
                    v-for="item in currencyListTokenSearch"
                    @click="chooseCoin('rightCoin', item, 'token')"
                    :key="item[1] + 'token'"
                    :data-value="item[2]"
                  >
                    <div class="coin-ico svgcoin" :class="item[2]"></div>
                    <div class="coin-outer">
                      <span class="coin-name"
                        >{{ item[0] }} ({{ item[1] }})</span
                      >
                    </div>
                  </li>
                </ul>
                <div class="ui-select-scroll-wrap hidden" data-height="100">
                  <div
                    class="ui-select-scroll"
                    style="top: 0px; height: 100%"
                  ></div>
                </div>
              </div>
            </div>
            <select id="select_currency_to" class="hidden">
              <option label="separate">Popular currencies</option>
              <option value="btc" data-symbol="BTC" data-sub="">Bitcoin</option>
            </select>
            <input
              class="input-amount"
              type="text"
              value=""
              id="select_amount_to"
              data-dir="to"
              maxlength="18"
              readonly="readonly"
              v-model="formData.receiveNum"
            />
            <div id="select_maxmin_to" class="hint maxmin">
              <span>min:</span>&nbsp;<button
                @click="getExchangePriceByToQty(exchangePrice.to.min)"
                type="button"
                class="maxmin-value"
                data-value="0.005"
              >
                {{ exchangePrice.to.min }} {{ rightCoin.sortName }}
              </button>
            </div>
            <span id="select_hinterror_to" class="hint error"></span>
            <label
              id="select_label_to"
              @click.stop="
                () => {
                  showLeftSelect = false;
                  showRightSelect = true;
                }
              "
            >
              <span class="coin-img svgcoin" :class="rightCoin.iconName"></span>
              <span v-if="rightCoin.sortName.indexOf('-') > -1"
                >{{ rightCoin.sortName.split("-")[0] }}
                <span> ({{ rightCoin.sortName.split("-")[1] }}) </span></span
              >
              <span v-else>{{ rightCoin.sortName }}</span>
            </label>
            <div class="input-rate rev" id="select_info_to"></div>
            <!-- <div class="input-rate" id="select_rate_to">
              1 {{ rightCoin.sortName }} ≈ {{ exchangePrice.to.rate }}
              {{ leftCoin.sortName }}
            </div> -->
          </div>
        </div>

        <!-- 地址输入框 -->
        <div class="input-box" style="margin-top: 24px">
          <div class="dinput" style="width: 100%;padding: 0" >
            <input
             
              style="width: 100%; text-align: left"
              class="nonextra my-input-p"
              :class="{
                error: !isvalidAddress && hadvalid,
              }"
              :placeholder="myPlaceholder(rightCoin.sortName)"
              type="text"
              id="receive_wallet"
              autocomplete="off"
              v-model="formData.address"
              @focus="isFocusAddress = true;"
              @blur="isFocusAddress = false"
              required
            />
            <span id="receive_wallet_error" class="hint">{{
              $t("index.addressHint")
            }}</span>
          </div>
        </div>

        <!-- 费率选择 -->
        <div class="exchange-option center clrfix">
          <div class="exchange-option-inner" style="width: 100%">
            <label class="radioselect">
              <input
                type="radio"
                name="select_type_from_1"
                value="fixed"
                v-model="formData.type"
              />
              <span
                :style="
                  formData.type == `float`
                    ? { outline: `#4B658A 2px solid` }
                    : { outline: `#4790F6 2px solid` }
                "
                >{{ $t("index.fixed") }} (1.0% + Fee)</span
              >
            </label>
            <label class="radioselect">
              <input
                type="radio"
                name="select_type_from_1"
                value="float"
                id="float_type"
                v-model="formData.type"
              />
              <span
                :style="
                  formData.type == `fixed`
                    ? { outline: `#4B658A 2px solid` }
                    : { outline: `#4790F6 2px solid` }
                "
                >{{ $t("index.float") }} (0.5% + Fee)</span
              >
            </label>
          </div>
          <br /><span
            style="margin-top: 20px"
            id="type_difference"
            class="exchange-option-diff"
          >
            <font>{{ $t("index.fee_hint") }}</font>
          </span>
        </div>

        <!-- submit 开始交换按钮 -->
        <div
          id="exchange_submit"
          class="submit"
          :class="{
            disabled: !isvalidAddress,
            loading: readyToOrder,
          }"
          @click.prevent="getExchangeMake"
        >
          <span>{{ $t("index.exchange_submit") }}</span>
        </div>
      </div>

      <!-- 右侧logo -->
      <div class="logo-container">
        <img src="/img/text-bg.svg" width="80%" alt="" />
      </div>

      <!-- ----------- -->
    </div>

    <!-- 移动端 -->
    <div class="mobile-box box-container">
      <!-- 顶部logo -->
      <div class="logo-container" style="position: relative; height: 128px">
        <img
          src="/img/logo-bg-1.png"
          width="36%"
          alt=""
          style="position: absolute; top: 0; left: 0"
        />

        <img
          src="/img/text-bg.svg"
          width="68%"
          alt=""
          style="position: absolute; top: 30px; left: 110px"
        />
      </div>
      <!-- 底部交换框 -->
      <div class="input-container">
        <div class="rate">
          1 {{ leftCoin.sortName }} ≈ {{ exchangePrice.from.rate }}
          {{ rightCoin.sortName }}
        </div>

        <!-- 币输入框 第一个 -->
        <div class="col">
          <div
            class="input-wabbr toleft locked"
            :data-value="leftCoin.iconName"
          >
            <div
              class="ui-select-outer with-search hidden-label"
              :class="{
                active: showLeftSelect,
              }"
              :data-value="leftCoin.iconName"
            >
              <label class="ui-select" :data-value="leftCoin.iconName">
                <div class="ui-select-value">
                  <div
                    class="coin-ico svgcoin"
                    :class="leftCoin.iconName"
                  ></div>
                  <div class="coin-outer">
                    <span class="coin-name">{{ leftCoin.name }}</span>
                  </div>
                </div>
                <div class="ui-select-arrow"></div>
                <div class="ui-select-search">
                  <span class="ui-select-search-ico ico"></span>
                  <div class="ui-select-search-inner">
                    <input
                      type="text"
                      placeholder="Type a currency"
                      v-model="searchText"
                    />
                  </div>
                </div>
              </label>

              <div class="ui-select-list my-ui-list">
                <ul style="padding-right: 0px" class="self-scorll">
                  <li class="ui-select-separate">Popular currencies</li>
                  <li
                    class="ui-select-option hover"
                    v-for="item in currencyListPopularSearch"
                    :key="item[1]"
                    @click="chooseCoin('leftCoin', item, 'popular')"
                    :data-value="item[2]"
                  >
                    <div class="coin-ico svgcoin" :class="item[2]"></div>
                    <div class="coin-outer">
                      <span class="coin-name"
                        >{{ item[0] }} ({{ item[1] }})</span
                      >
                    </div>
                  </li>
                  <li class="ui-select-separate">All currencies</li>
                  <li
                    class="ui-select-option hover"
                    v-for="item in currencyListCoinSearch"
                    :key="item[1] + 'coin'"
                    @click="chooseCoin('leftCoin', item, 'coin')"
                    :data-value="item[2]"
                  >
                    <div class="coin-ico svgcoin" :class="item[2]"></div>
                    <div class="coin-outer">
                      <span class="coin-name"
                        >{{ item[0] }} ({{ item[1] }})</span
                      >
                    </div>
                  </li>
                  <li
                    class="ui-select-option hover"
                    v-for="item in currencyListTokenSearch"
                    @click="chooseCoin('leftCoin', item, 'token')"
                    :key="item[1] + 'token'"
                    :data-value="item[2]"
                  >
                    <div class="coin-ico svgcoin" :class="item[2]"></div>
                    <div class="coin-outer">
                      <span class="coin-name"
                        >{{ item[0] }} ({{ item[1] }})</span
                      >
                    </div>
                  </li>
                </ul>
                <div class="ui-select-scroll-wrap hidden" data-height="100">
                  <div
                    class="ui-select-scroll"
                    style="top: 0px; height: 100%"
                  ></div>
                </div>
              </div>
            </div>

            <select id="select_currency_from" class="hidden">
              <option label="separate">Popular currencies</option>
              <option selected="" value="btc" data-symbol="BTC" data-sub="">
                Bitcoin
              </option>
            </select>
            <input
              class="input-amount"
              type="text"
              value=""
              id="select_amount_from"
              data-dir="from"
              maxlength="18"
              v-model.number="formData.sendNum"
              @change="changeSendNum"
            />
            <div id="select_maxmin_from" class="hint maxmin">
              <span>min:</span>&nbsp;<button
                @click="
                  () => {
                    formData.sendNum = exchangePrice.from.min;
                    getExchangePrice();
                  }
                "
                type="button"
                class="maxmin-value"
              >
                {{ exchangePrice.from.min }} {{ leftCoin.sortName }}
              </button>
            </div>
            <span id="select_hinterror_from" class="hint error"></span>
            <label
              id="select_label_from"
              @click.stop="
                () => {
                  showLeftSelect = true;
                  showRightSelect = false;
                }
              "
            >
              <span v-if="leftCoin.sortName.indexOf('-') > -1"
                >{{ leftCoin.sortName.split("-")[0]
                }}<sup>{{ leftCoin.sortName.split("-")[1] }}<sup></sup></sup
              ></span>
              <span v-else>{{ leftCoin.sortName }}</span>
              <span class="coin-img svgcoin" :class="leftCoin.iconName"></span>
              <span class="my-border"></span>
            </label>
            <div class="input-rate rev" id="select_info_from" v-show="false">
              <button
                class="btn-text tolightning"
                type="button"
                data-dir="from"
              >
                <span class="ico lightning" data-value="btcln"></span>
                <span>Try lightning!</span>
              </button>
            </div>
            <!-- <div class="input-rate" id="select_rate_from">
              1 {{ leftCoin.sortName }} ≈ {{ exchangePrice.from.rate }}
              {{ rightCoin.sortName }}
            </div> -->
          </div>
        </div>

        <!-- 交换框按钮 -->
        <div class="change-btn" @click.prevent="exchangeCoin">
          <img width="20px" height="20px" src="/img/change-btn.svg" alt="" />
        </div>

        <div class="rate">
          1 {{ rightCoin.sortName }} ≈ {{ exchangePrice.to.rate }}
          {{ leftCoin.sortName }}
        </div>

        <!-- 币输入框 第二个 -->
        <div class="col">
          <!-- <header>{{ $t("index.RECEIVE") }}</header> -->
          <div class="input-wabbr toright" :data-value="rightCoin.iconName">
            <div
              class="ui-select-outer with-search hidden-label"
              :class="{
                active: showRightSelect,
              }"
              :data-value="rightCoin.iconName"
            >
              <label class="ui-select" :data-value="rightCoin.iconName">
                <div class="ui-select-value">
                  <div class="coin-ico svgcoin eth"></div>
                  <div class="coin-outer">
                    <span class="coin-name">Ethereum</span>
                  </div>
                </div>
                <div class="ui-select-arrow"></div>
                <div class="ui-select-search">
                  <span class="ui-select-search-ico ico"></span>
                  <div class="ui-select-search-inner">
                    <input
                      type="text"
                      placeholder="Type a currency"
                      v-model="searchText"
                    />
                  </div>
                </div>
              </label>
              <div class="ui-select-list my-ui-list">
                <ul style="padding-right: 0px" class="self-scorll">
                  <li class="ui-select-separate">Popular currencies</li>
                  <li
                    class="ui-select-option hover"
                    v-for="item in currencyListPopularSearch"
                    :key="item[1]"
                    @click="chooseCoin('rightCoin', item, 'popular')"
                    :data-value="item[2]"
                  >
                    <div class="coin-ico svgcoin" :class="item[2]"></div>
                    <div class="coin-outer">
                      <span class="coin-name"
                        >{{ item[0] }} ({{ item[1] }})</span
                      >
                    </div>
                  </li>
                  <li class="ui-select-separate">All currencies</li>
                  <li
                    class="ui-select-option hover"
                    v-for="item in currencyListCoinSearch"
                    :key="item[1] + 'coin'"
                    @click="chooseCoin('rightCoin', item, 'coin')"
                    :data-value="item[2]"
                  >
                    <div class="coin-ico svgcoin" :class="item[2]"></div>
                    <div class="coin-outer">
                      <span class="coin-name"
                        >{{ item[0] }} ({{ item[1] }})</span
                      >
                    </div>
                  </li>
                  <li
                    class="ui-select-option hover"
                    v-for="item in currencyListTokenSearch"
                    @click="chooseCoin('rightCoin', item, 'token')"
                    :key="item[1] + 'token'"
                    :data-value="item[2]"
                  >
                    <div class="coin-ico svgcoin" :class="item[2]"></div>
                    <div class="coin-outer">
                      <span class="coin-name"
                        >{{ item[0] }} ({{ item[1] }})</span
                      >
                    </div>
                  </li>
                </ul>
                <div class="ui-select-scroll-wrap hidden" data-height="100">
                  <div
                    class="ui-select-scroll"
                    style="top: 0px; height: 100%"
                  ></div>
                </div>
              </div>
            </div>
            <select id="select_currency_to" class="hidden">
              <option label="separate">Popular currencies</option>
              <option value="btc" data-symbol="BTC" data-sub="">Bitcoin</option>
            </select>
            <input
              class="input-amount"
              type="text"
              value=""
              id="select_amount_to"
              data-dir="to"
              maxlength="18"
              readonly="readonly"
              v-model="formData.receiveNum"
            />
            <div id="select_maxmin_to" class="hint maxmin">
              <span>min:</span>&nbsp;<button
                @click="getExchangePriceByToQty(exchangePrice.to.min)"
                type="button"
                class="maxmin-value"
                data-value="0.005"
              >
                {{ exchangePrice.to.min }} {{ rightCoin.sortName }}
              </button>
            </div>
            <span id="select_hinterror_to" class="hint error"></span>
            <label
              id="select_label_to"
              @click.stop="
                () => {
                  showLeftSelect = false;
                  showRightSelect = true;
                }
              "
            >
              <span class="coin-img svgcoin" :class="rightCoin.iconName"></span>
              <span v-if="rightCoin.sortName.indexOf('-') > -1"
                >{{ rightCoin.sortName.split("-")[0]
                }}<sup>{{ rightCoin.sortName.split("-")[1] }}<sup></sup></sup
              ></span>
              <span v-else>{{ rightCoin.sortName }}</span>
            </label>
            <div class="input-rate rev" id="select_info_to"></div>
            <!-- <div class="input-rate" id="select_rate_to">
              1 {{ rightCoin.sortName }} ≈ {{ exchangePrice.to.rate }}
              {{ leftCoin.sortName }}
            </div> -->
          </div>
        </div>

        <!-- 地址输入框 -->
        <div class="input-box" style="margin-top: 24px">
          <div class="dinput" style="width: 100%;padding: 0;">
            <input
              style="width: 100%; text-align: left"
              class="nonextra my-input-p"
              :class="{
                error: !isvalidAddress && hadvalid,
              }"
              :placeholder="myPlaceholder(rightCoin.sortName)"
              type="text"
              id="receive_wallet"
              autocomplete="off"
              v-model="formData.address"
              @focus="isFocusAddress = true;"
              @blur="isFocusAddress = false"
              required
            />
            <span id="receive_wallet_error" class="hint">{{
              $t("index.addressHint")
            }}</span>
          </div>
        </div>

        <!-- 费率选择 -->
        <div class="exchange-option center clrfix">
          <div class="exchange-option-inner">
            <label class="radioselect">
              <input
                type="radio"
                name="select_type_from"
                value="fixed"
                v-model="formData.type"
              />
              <span>{{ $t("index.fixed") }} (1.0% + Fee)</span>
            </label>
            <label class="radioselect">
              <input
                type="radio"
                name="select_type_from"
                value="float"
                id="float_type"
                v-model="formData.type"
              />
              <span>{{ $t("index.float") }} (0.5% + Fee)</span>
            </label>
          </div>
          <br /><span
            style="margin-top: 20px"
            id="type_difference"
            class="exchange-option-diff"
          >
            <font>{{ $t("index.fee_hint") }}</font>
          </span>
        </div>

        <!-- submit 开始交换按钮 -->
        <div
          id="exchange_submit"
          class="submit"
          :class="{
            disabled: !isvalidAddress,
            loading: readyToOrder,
          }"
          @click.prevent="getExchangeMake"
        >
          <span>{{ $t("index.exchange_submit") }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import colorList from "@/plugins/data/colors";
import { outline } from "@antv/x6/lib/registry/filter/outline";

export default {
  data() {
    return {
      searchText: "",
      currencyList: {
        coin: [],
        token: [],
        popular: [],
      },
      showLeftSelect: false,
      showRightSelect: false,
      leftCoin: {
        type: "coin",
        name: "",
        sortName: "",
      },
      rightCoin: {
        type: "coin",
        name: "",
        sortName: "",
      },
      formData: {
        sendNum: 0,
        receiveNum: "0",
        type: "float",
        address: "",
      },
      exchangePrice: {
        from: {
          min: 0,
          rate: 0,
        },
        to: {
          min: 0,
          rate: 0,
        },
        usd: "??",
      },
      isvalidAddress: false,
      hadvalid: false,
      isChangingAddress: false,
      isFocusAddress: false,
      readyToOrder: false,
      colorList,
    };
  },
  created() {
    this.getCurrencyList();
  },
  mounted() {
    this.getNotice();
    document.addEventListener('click', this.changeSelectShow, true);
    //document.addEventListener('keydown', this.keydownEvent, true);
    //document.addEventListener('keyup', this.keyupEvent, true);
  },
  watch: {
    "formData.type"() {
      this.getExchangePrice();
    },
    "$route.query": {
      handler() {
        console.log(this.$route.query);
        // 执行代码部分
        let { from, to, lang } = this.$route.query;
        if (lang) {
          this.getNotice();
        }
        if (from && to) {
          this.currencyList.popular.forEach((item) => {
            if (item[1] === from) {
              this.chooseCoin("leftCoin", item, "coin", true);
            } else if (item[1] === to) {
              this.chooseCoin("rightCoin", item, "coin", true);
            }
          });
          this.formData.sendNum = 0;
          this.getExchangePrice(true);
          this.$scrollToTop();
        }
      },
    },
    "formData.address"() {
     
      if (this.formData.address.length > 30) {
          this.hadvalid = false;
          if (window.xtimeout) {
            clearTimeout(window.xtimeout);
          }

          // 设置新的 timeout
          window.xtimeout = setTimeout(() => {
            this.validAddress();
          }, 500); // 设定为1秒后执行
        
      }else{
        this.hadvalid = this.formData.address.length == 0 ? false : true;
        this.isvalidAddress = false;
      }
    },
  },
  methods: {
    changeSelectShow(e){
      if(this.$refs.leftCoinArea && !this.$refs.leftCoinArea.contains(e.target)){
        this.showLeftSelect=false;
      }
      if(this.$refs.rightCoinArea && !this.$refs.rightCoinArea.contains(e.target)){
        this.showRightSelect=false;
      }
      if (this.formData.address.length == 0) {
        this.hadvalid = false;
      }
    },
    keyupEvent(e){
        if (this.isChangingAddress) {
            if (this.formData.address.length > 30){
                this.validAddress();
            }
            else{
                this.hadvalid = this.formData.address.length == 0 ? false : true;
                this.isvalidAddress = false;
            }
        }
        this.isChangingAddress = false;
    },
    keydownEvent(e){
        console.log(123);
        if (this.isFocusAddress) {
            this.isChangingAddress = true;
        }
    },
    getNotice() {
      var ts_last = this.$cookies.get("notice_ts");
      var ts = parseInt(new Date().getTime() / 1000);
      if (!ts_last) {
        this.$cookies.set("notice_ts", ts);
      } else {
        if (ts - ts_last < 3600 * 24) {
          return;
        } else {
          this.$cookies.set("notice_ts", ts);
        }
      }
      var lang_index = this.$cookies.get("langIndex");
      this.$api.getNotice().then((res) => {
        if (res.data.show) {
          this.$modal.show("dialog", {
            title: res.data.content[lang_index][0],
            text:
              res.data.content[lang_index][1] +
              res.data.scam_list.join("<br />"),
            buttons: [
              {
                title: res.data.content[lang_index][2],
                handler: () => {
                  this.$modal.hide("dialog");
                },
              },
            ],
          });
        }
      });
    },
    getCurrencyList() {
      console.log("getCurrencyList");
      this.$api.getCurrencyList().then((res) => {
        let currencyList = res.data;
        for (let key in currencyList) {
          currencyList[key].forEach((item) => {
            item[2] = item[2][1];
          });
        }
        this.currencyList = currencyList;
        this.chooseCoin("leftCoin", this.currencyList.popular[3], "coin", true);
        this.chooseCoin(
          "rightCoin",
          this.currencyList.popular[4],
          "coin",
          true
        );

        this.getExchangePrice(true);
      });
    },
    getExchangePrice(isFresh = false) {
      this.$api
        .getExchangePrice({
          from_currency: this.leftCoin.sortName,
          to_currency: this.rightCoin.sortName,
          from_qty: this.formData.sendNum,
          type: this.formData.type,
          // to_qty: "",
        })
        .then((res) => {
          this.exchangePrice = res.data;
          this.formData.receiveNum = "≈" + res.data.to.amount;
          if (isFresh || this.formData.sendNum == 0) {
            this.formData.sendNum = this.exchangePrice.from.suggest;
            this.formData.receiveNum = "≈" + res.data.to.suggest;
            this.exchangePrice.from.rate = this.exchangePrice.from.rate_suggest;
            this.exchangePrice.to.rate = this.exchangePrice.to.rate_suggest;
            this.exchangePrice.usd = this.exchangePrice.usd_suggest;
          }
          if (this.formData.sendNum < this.exchangePrice.from.min) {
            this.formData.sendNum = this.exchangePrice.from.min;
          }
        });
    },
    getExchangePriceByToQty(to_qty) {
      this.$api
        .getExchangePrice({
          from_currency: this.leftCoin.sortName,
          to_currency: this.rightCoin.sortName,
          type: this.formData.type,
          to_qty,
        })
        .then((res) => {
          this.exchangePrice = res.data;
          this.formData.receiveNum = "≈" + res.data.to.amount;
          this.formData.sendNum = res.data.from.amount;
        });
    },
    validAddress() {
      this.hadvalid = false;
      this.isvalidAddress = false;
      if (this.formData.address == "") {
        return;
      }
      this.$api
        .validAddress({
          currency: this.rightCoin.sortName,
          address: this.formData.address,
        })
        .then((res) => {
          this.hadvalid = true;
          this.isvalidAddress = res.data.valid;
        });
    },
    chooseCoin(key, item, type, isDefault = false) {
      // console.log("选择");
      this.searchText = "";
      if (
        (key == "leftCoin" && this.rightCoin.sortName === item[1]) ||
        (key == "rightCoin" && this.leftCoin.sortName === item[1])
      ) {
        this.showLeftSelect = false;
        this.showRightSelect = false;
        this.exchangeCoin();
        return;
      }
      this[key] = {
        type,
        name: item[0],
        sortName: item[1],
        iconName: item[2],
      };
      this.showLeftSelect = false;
      this.showRightSelect = false;
      this.formData.sendNum = 0;
      if (!isDefault) {
        this.getExchangePrice();
      }
      this.validAddress();
    },
    changeSendNum() {
      if (this.formData.sendNum < this.exchangePrice.from.min - 0) {
        this.formData.sendNum = this.exchangePrice.from.min;
        return;
      }
      this.getExchangePrice();
    },
    getExchangeMake() {
      if (!this.isvalidAddress || this.readyToOrder) {
        this.hadvalid = true;
        return console.log("请输入正确的地址");
      }
      this.readyToOrder = true;
      this.$api
        .getExchangeMake({
          from_currency: this.leftCoin.sortName,
          to_currency: this.rightCoin.sortName,
          from_qty: this.formData.sendNum,
          type: this.formData.type,
          toAddress: this.formData.address,
        })
        .then((res) => {
          this.$router.push({
            path: "/main/order",
            query: { orderId: res.data.order_id },
          });
        })
        .finally(() => {
          this.readyToOrder = false;
        });
    },
    exchangeCoin() {
      let temporary = { ...this.leftCoin };
      this.leftCoin = { ...this.rightCoin };
      this.rightCoin = { ...temporary };
      this.formData.sendNum = this.formData.receiveNum.replace("≈", "") - 0;
      this.getExchangePrice();
      this.validAddress();
    },

    //自定义 placeholder
    myPlaceholder: function (name) {
      return this.$t("index.receive_wallet_label", {
        sortName: name,
      });
    },
  },
  computed: {
    currencyListPopularSearch: function () {
      let arr = [];
      this.currencyList.popular.forEach((item) => {
        if (
          item[0].match(new RegExp(this.searchText, "ig")) ||
          item[1].match(new RegExp(this.searchText, "ig"))
        ) {
          arr.push(item);
        }
      });
      console.log(arr, "currencyListPopularSearch");
      return arr;
    },
    currencyListCoinSearch: function () {
      let arr = [];
      this.currencyList.coin.forEach((item) => {
        if (
          item[0].match(new RegExp(this.searchText, "ig")) ||
          item[1].match(new RegExp(this.searchText, "ig"))
        ) {
          arr.push(item);
        }
      });
      return arr;
    },
    currencyListTokenSearch: function () {
      let arr = [];
      this.currencyList.token.forEach((item) => {
        if (
          item[0].match(new RegExp(this.searchText, "ig")) ||
          item[1].match(new RegExp(this.searchText, "ig"))
        ) {
          arr.push(item);
        }
      });
      return arr;
    },
  },
};
</script>

<style lang="less">
* {
  box-sizing: border-box;
}
.container .box-container{

  .disabled {
  background: linear-gradient(270deg, #84a4ff 100%, #92aaed 100%) !important;
  // background: linear-gradient(270deg, rgb(71, 117, 246) 0%, rgb(41, 87, 208) 100%) !important;
}
}

.input-box > input {
  &::placeholder {
    color: #7992c1;

    font-size: 14px;
    font-weight: 400;
    line-height: 16.88px;
    text-align: left;
  }
}

label.radioselect > input:checked + span {
  background-color: #4790f6;
}
label.radioselect > span {
  border-style: none;
}
.input-wabbr > input {
  border-bottom: 0px;
}

.input-wabbr.toleft > input {
  padding-right: 0;
}

.input-wabbr > input {
  border-bottom: 0px;
  text-align: right;
  padding: 0;
  // padding-right: 0;
}

.input-wabbr.toleft > label {
  right: 0;
}
.input-wabbr > label:before {
  border-style: none;
}
.my-border {
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  border-style: solid;
  border-width: 8px 5px;
  display: inline-block;
  vertical-align: middle;
  transform: translateY(28%);
  margin-left: 10px;
}

.input-wabbr.toright > label:after {
  content: "";
  margin-left: 10px;
}
/** pc 端 */
@media screen and (min-width: 900px) {
  .pc-box {
    display: flex;
  }
  .mobile-box {
    display: none;
  }
  .container {
    width: 80%;
    max-width: 1200px;

    margin: 0 auto;
    margin-top: 47px;

    .box-container {
      // display: flex;
      justify-content: center;
      align-items: center;
      // border: 1px solid red;

      > div {
        // width: 50%;
        height: 570px;
      }

      .input-container {
        .exchange-option-inner {
          margin-top: 20px;
          // border: 2px solid #4b658a;
          border-radius: 10px;
          > input {
            outline: none;
            border-style: none;
          }
          .label.radioselect > input:checked {
            background-color: red !important;
          }
        }
        background: linear-gradient(
          180deg,
          #2a467e 0%,
          #253e74 32.15%,
          #1c2d5f 100%
        );

        .col {
          box-sizing: border-box;
          background: #0000001f;
          border-radius: 10px;
          padding: 0 10px;
          height: 56px;
          padding-bottom: 15px;
          margin-top: 10px;
        }

        /**------------ */
        border: 1px solid #3f5e9d;
        border-radius: 15px;
        padding: 32px;
        display: flex;
        flex-direction: column;
        .rate {
          font-size: 12px;
          font-weight: 400;
          line-height: 14.47px;
          color: #ffffff;
        }

        .input-box {
          width: 100%;
          height: 56px;
          background: #0000001f;
          margin-top: 10px;
          border-radius: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px;
          position: relative;
          .ui-select-outer {
            position: absolute;
            top: 0px;
            left: 0;
            width: 100%;
          }

          .ui-select-search {
            border-color: #1c2d5f !important;
          }
          .left {
            display: flex;
            justify-content: start;
            align-items: center;
          }
          .right {
            background-color: transparent;
            outline: none;
            border-style: none;

            height: 100%;
            width: 60%;

            font-family: Bebas;
            font-size: 22px;
            font-weight: 400;
            line-height: 29.11px;
            color: #4790f6;
            text-align: right;
          }

          .address {
            width: 100%;
            height: 100%;
            background-color: transparent;
            outline: none;
            border-style: none;

            font-size: 14px;
            font-weight: 400;
            line-height: 16.88px;
            text-align: left;
            color: #7992c1;
          }
        }

        .change-btn {
          width: 40px;
          min-height: 40px;
          background: #4790f6;
          border-radius: 50%;
          margin: 20px auto 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          &:active {
            opacity: 0.5;
          }
        }

        .rate-select {
          width: 100%;
          height: 44px;
          border: 2px solid #4b658a;
          margin-top: 24px;
          display: flex;
          align-items: center;
          border-radius: 14px;
          overflow: hidden;
          > div {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
          }

          .active {
            background-color: #4790f6;
          }
        }

        .text-info {
          font-size: 12px;
          font-weight: 400;
          line-height: 14.47px;
          text-align: center;
          color: #ffffff;
          padding: 0 20px;
          margin-top: 12px;
        }

        .submit {
          width: 100%;
          height: 52px;
          border: 0.5px 0px 0px 0px;
          background: linear-gradient(270deg, #4775f6 0%, #2957d0 100%);
          border: 0.5px solid #576cf2;
          margin-top: 22px;
          border-radius: 25px;
          box-shadow: 0px 4px 12px 0px #1d72e767;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          > span {
            font-family: Bebas;
            font-size: 16px;
            font-weight: 400;
            line-height: 21.17px;

            color: #ffffff;
          }
        }
      }

      .logo-container {
        background-image: url("/img/logo-bg-1.png");
        background-repeat: no-repeat;
        // background-position: 0 -120px;
        background-size: contain;
        margin-left: 40px;
        // max-width: 1200px;
        // background-color: red;
        width: 530px;
        // height: 500px;
      }
    }
  }
}

/**
  移动端
  */
@media screen and (max-width: 900px) {
  .pc-box {
    display: none;
  }
  .mobile-box {
    display: flex;
  }
  .input-wabbr > input {
    border-bottom: 0px;
    text-align: right;
    padding: 0;
    // padding-right: 0;
  }

  .input-wabbr.toleft > label {
    left: 0;
    // width: auto;
    width: 50%;
    // border: 1px solid red;
    display: inline-block;
  }
  .input-wabbr.toright > label {
    left: 0;
    width: auto;
    // border: 1px solid red;
    display: inline-block;
  }
  .input-wabbr.toright > input {
    text-align: right;
  }
  .input-wabbr.toright > input {
    padding-right: 0;
  }

  .container {
    width: 100%;

    margin: 0 auto;
    padding: 10px;
    .box-container {
      // display: flex;
      flex-direction: column;

      align-items: center;
      .input-container {
        z-index: 2;
        .exchange-option-inner {
          margin-top: 20px;
          border: 2px solid #4b658a;
          border-radius: 10px;
          > input {
            outline: none;
            border-style: none;
          }
          .label.radioselect > input:checked {
            background-color: red !important;
          }
        }
        background: linear-gradient(
          180deg,
          #2a467e 0%,
          #253e74 32.15%,
          #1c2d5f 100%
        );

        .col {
          box-sizing: border-box;
          background: #0000001f;
          border-radius: 10px;
          padding: 0 10px;
          height: 56px;
          padding-bottom: 15px;
          margin-top: 10px;
        }

        /**------------ */
        border: 1px solid #3f5e9d;
        border-radius: 15px;
        padding: 32px;
        display: flex;
        flex-direction: column;
        .rate {
          font-size: 12px;
          font-weight: 400;
          line-height: 14.47px;
          color: #ffffff;
        }

        .input-box {
          width: 100%;
          height: 56px;
          background: #0000001f;
          margin-top: 10px;
          border-radius: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px;
          position: relative;
          .ui-select-outer {
            position: absolute;
            top: 0px;
            left: 0;
            width: 100%;
          }

          .ui-select-search {
            border-color: #1c2d5f !important;
          }
          .left {
            display: flex;
            justify-content: start;
            align-items: center;
          }
          .right {
            background-color: transparent;
            outline: none;
            border-style: none;

            height: 100%;
            width: 60%;

            font-family: Bebas;
            font-size: 22px;
            font-weight: 400;
            line-height: 29.11px;
            color: #4790f6;
            text-align: right;
          }

          .address {
            width: 100%;
            height: 100%;
            background-color: transparent;
            outline: none;
            border-style: none;

            font-size: 14px;
            font-weight: 400;
            line-height: 16.88px;
            text-align: left;
            color: #7992c1;
          }
        }

        .change-btn {
          width: 40px;
          min-height: 40px;
          background: #4790f6;
          border-radius: 50%;
          margin: 20px auto 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          &:active {
            opacity: 0.5;
          }
        }

        .rate-select {
          width: 100%;
          height: 44px;
          border: 2px solid #4b658a;
          margin-top: 24px;
          display: flex;
          align-items: center;
          border-radius: 14px;
          overflow: hidden;
          > div {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
          }

          .active {
            background-color: #4790f6;
          }
        }

        .text-info {
          font-size: 12px;
          font-weight: 400;
          line-height: 14.47px;
          text-align: center;
          color: #ffffff;
          padding: 0 20px;
          margin-top: 12px;
        }

        .submit {
          width: 100%;
          height: 52px;
          border: 0.5px 0px 0px 0px;
          background: linear-gradient(270deg, #4775f6 0%, #2957d0 100%);
          border: 0.5px solid #576cf2;
          margin-top: 42px;
          border-radius: 25px;
          box-shadow: 0px 4px 12px 0px #1d72e767;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          > span {
            font-family: Bebas;
            font-size: 16px;
            font-weight: 400;
            line-height: 21.17px;

            color: #ffffff;
          }
        }
      }

      .logo-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        // max-width: 1200px;
      }
    }
  }

  .container .box-container .input-container .rate {
    color: rgba(255, 255, 255, 0.7) !important;
  }
}

@media only screen and(max-width: 900px) {
  .container .box-container .input-container {
    padding: 22px !important;
  }
  .container .box-container .input-container .col {
    height: 44px;
  }

  .exchange-option-diff {
    padding: 0px 5px;
  }
  #select_label_from > :first-child {
    font-weight: 700;
    margin-left: 25px;
    min-width: 63px;
  }
  .input-wabbr.toleft .coin-img {
    margin-left: -138px;
    margin-right: 80px;
  }

  .input-wabbr.toright > label:after {
    margin-left: 20px;
    margin-top: -13px;
  }
  .input-wabbr > label > :nth-child(2) {
    font-weight: 700;
    width: 63px;
    margin-left: 8px;
  }
  .input-wabbr .ui-select-option {
    padding: 0.688em 2em 0.688em 1em;
  }
  .container .box-container .input-container .input-box {
    height: 44px;
  }
  .container .box-container .input-container .submit {
    height: 44px;
  }
  .ui-select-outer.hidden-label.active {
    width: 100%;
    margin: 0 auto;
  }
}
@media screen and (max-width: 900px) {
  .container .box-container .input-container .change-btn {
    width: 32px;
    min-height: 32px;
  }
  .container .box-container .input-container .change-btn > img {
    width: 17px;
    height: 17px;
  }
  .input-wabbr.toleft > label {
    top: 4px;
  }
  .input-wabbr.toright > label {
    top: 4px;
  }
  @media screen and (max-width: 768px) {
    .mobile {
      color: rgba(140, 145, 175, 1);
    }
  }
}
@media only screen and (max-width: 700px) {
  .my-faq > li {
    padding: 22px 38px 38px 38px !important;
  }
  .my-faq > li > p {
    font-size: 12px !important;
    word-spacing: 4px;
  }
  .fl {
    margin-bottom: 30px;
  }
}
.loc .ui-select-value > span {
  font-weight: 700;
}
.input-wabbr > input {
  font-weight: 700;
}
#select_label_from > :first-child {
  font-weight: 700;
}
.input-wabbr > label > :nth-child(2) {
  font-weight: 700;
}
// @media screen and (min-width: 900px){
//   .container .box-container{
//     display: none !important;
//   }
// }
.container-1 .box-container .input-container .change-btn {
  opacity: 0.6;
  cursor: initial !important;
}

.my-input-p {
  &::placeholder {

    font-size: 14px;
    font-weight: 400;
    line-height: 16.88px;
    text-align: left;
    color: rgba(121, 146, 193, 1);
  }
}

</style>
