export default  {
    btc: '#f7931a',
    btcbnb: '#f7931a',
    btcbsc: '#f7931a',
    btcln: '#9157ff',
    etc: '#8cbd9b',
    eth: '#fff',
    ltc: '#497ed1',
    zec: '#ffbf20',
    ada: '#0033ad',
    adabnb: '#0033ad',
    adabsc: '#0033ad',
    bat: '#ff0649',
    batbsc: '#ff0649',
    bch: '#85c459',
    bnb: '#ffcc4f',
    bsc: '#ffcc4f',
    busd: '#ffcc4f',
    busdbsc: '#ffcc4f',
    busdeth: '#ffcc4f',
    bsv: '#eab300',
    btg: '#ffd827',
    btt: '#000',
    cake: '#43d2da',
    daieth: '#f4b731',
    daibsc: '#f4b731',
    dash: '#008ce3',
    doge: '#ba9f33',
    drgn: '#28a2ea',
    eos: '#8997d8',
    eurs: '#15bcff',
    gnt: '#06a8c6',
    link: '#3f6ff3',
    lsk: '#2979d8',
    matic: '#8247e5',
    maticeth: '#8247e5',
    mkr: '#10bc99',
    neo: '#92d71b',
    omg: '#235eff',
    pax: '#c7e432',
    qtum: '#41abe0',
    shib: '#f4a816',
    shibbsc: '#f4a816',
    trx: '#ff2e4b',
    tube: '#86af49',
    tusd: '#5dc9bc',
    twt: '#478dd7',
    usdp: '#c7e432',
    usdc: '#2775ca',
    usdctrc: '#2775ca',
    usdcbsc: '#2775ca',
    usdt: '#53ae94',
    usdterc: '#53ae94',
    usdttrc: '#53ae94',
    usdtbnb: '#53ae94',
    usdtbsc: '#53ae94',
    ustbsc: '#5d9cff',
    waves: '#076dd4',
    xem: '#00c4b3',
    xlm: '#000',
    xmr: '#e06b1a',
    xrp: '#0c89ca',
    xtz: '#4284ff',
    zrx: '#000',
    gram: '#21a6ef'
}