<template>
  <main
    @click="
      () => {
        showLeftSelet = false;
        showRightSelet = false;
      }
    "
  >
  <div class="gradient-bg"></div>
<div style="position: relative;">
   <!-- 便捷跨鏈兌換服務 -->
   <section
      id="index_main"
      class="main-section withheader darkbg"
     
      key="index_main"
    >
      <!-- 头部header -->
      <Iheader></Iheader>

      <!-- 交换区域 -->
      <change-box></change-box>

      <!-- 深度交换 -->
      <p
        style="
          
          font-size: 16px;
          font-weight: 400;
          line-height: 19.3px;
          display: none;
          align-items: center;
          color: #f8d456;
          width: 80%;
          margin: 33px auto;
          max-width: 1200px;
        "
      >
        <a href="/#/main/deep" style=" color: #f8d456;">
          {{ $t("index.deepHint") }}
        </a>
        <img style="margin-left: 10px" width="22px" src="/img/go.png" alt="" />
      </p>
    </section>

    <!-- 簡易且全自動跨鏈兌換流程帶來最快的兌換速度 -->
    <change-info></change-info>

    <!-- FAQ -->

    <section
      class="faq-section"
      style="background-color: transparent"
      key="faq"
    >
      <div class="wrapper clrfix">
        <h2
          class="center"
          style="
            color: #ffffff;
            font-family: Bebas;
            font-size: 20px;
            font-weight: 400;
            line-height: 26.46px;
          "
        >
          FAQ
        </h2>
        <ul
         
          class="mini-faq clrfix my-faq"
        >
          <li>
            <h4>{{ $t("index.faq0Name") }}</h4>
            <p>
              {{ $t("index.faq0Content") }}
            </p>
          </li>
          <li>
            <h4>{{ $t("index.faq1Name") }}</h4>
            <p>
              {{ $t("index.faq1Content") }}
            </p>
            <p class="reviews-images" v-if="false">
              <a
                rel="noopener noreferrer"
                target="_blank"
                class="svgreviews tw"
                title="twitter.com"
                href="https://twitter.com/fixedfloat"
              ></a>
              <a
                rel="noopener noreferrer"
                target="_blank"
                class="svgreviews tg"
                title="Telegram"
                href="https://t.me/FixedFloat"
              ></a>
              <a
                rel="noopener noreferrer"
                target="_blank"
                class="svgreviews medium"
                title="medium.com"
                href="https://medium.com/fixedfloat"
              ></a>
              <a
                rel="noopener noreferrer"
                target="_blank"
                class="svgreviews instagram"
                title="instagram.com"
                href="https://www.instagram.com/fixedfloat/"
              ></a>
              <a
                rel="noopener noreferrer"
                target="_blank"
                class="svgreviews reddit"
                title="reddit.com"
                href="https://www.reddit.com/user/FixedFloat/"
              ></a>
              <a
                rel="noopener noreferrer"
                target="_blank"
                class="svgreviews bitcointalk"
                title="bitcointalk.org"
                href="https://bitcointalk.org/index.php?topic=5107342.new#new"
              ></a>
              <a
                rel="noopener noreferrer"
                target="_blank"
                class="svgreviews bestchange"
                title="bestchange.com"
                href="https://www.bestchange.com/fixedfloat-exchanger.html"
              ></a>
              <a
                rel="noopener noreferrer"
                target="_blank"
                class="svgreviews trustpilot"
                title="trustpilot.com"
                href="https://www.trustpilot.com/review/fixedfloat.com"
              ></a>
            </p>
          </li>
          <li>
            <h4>{{ $t("index.faq2Name") }}</h4>
            <p>
              {{ $t("index.faq2Content") }}
            </p>
            <p v-if="false">
              •  1% if you opt for a <b>fixed rate</b><br />•  0.5% if you opt
              for a <b>floating rate</b>
            </p>
          </li>
        </ul>
        <div class="mini-faq-more" v-if="false">
          <a href="/#/faq"
            ><span>Go to page FAQ</span> <i class="ico arrow-forward"></i
          ></a>
        </div>
      </div>
    </section>
</div>
 
  
  </main>
</template>

<script>
import Iheader from "@/components/header.vue";
import changeBox from "../../../components/changeBox.vue";
import colorList from "@/plugins/data/colors";
import changeInfo from "@/components/changeInfo.vue";
// import ChangeInfo from "../../../components/changeInfo.vue";
export default {
  name: "homePage",
  components: {
    Iheader,
    changeBox,
    changeInfo,
  
  },
  data() {
    return {
      searchText: "",
      currencyList: {
        coin: [],
        token: [],
        popular: [],
      },
      showLeftSelet: false,
      showRightSelet: false,
      leftCoin: {
        type: "coin",
        name: "",
        sortName: "",
      },
      rightCoin: {
        type: "coin",
        name: "",
        sortName: "",
      },
      formData: {
        sendNum: 0,
        receiveNum: "0",
        type: "float",
        address: "",
      },
      exchangePrice: {
        from: {
          min: 0,
          rate: 0,
        },
        to: {
          min: 0,
          rate: 0,
        },
        usd: "??",
      },
      isvalidAddress: false,
      hadvalid: false,
      readyToOrder: false,
      colorList,
    };
  },
  created() {
    this.getCurrencyList();
  },
  mounted() {
    this.getNotice();
  },
  watch: {
    "formData.type"() {
      this.getExchangePrice();
    },
    "$route.query": {
      handler() {
        console.log(this.$route.query);
        // 执行代码部分
        let { from, to, lang } = this.$route.query;
        if (lang) {
          this.getNotice();
        }
        if (from && to) {
          this.currencyList.popular.forEach((item) => {
            if (item[1] === from) {
              this.chooseCoin("leftCoin", item, "coin", true);
            } else if (item[1] === to) {
              this.chooseCoin("rightCoin", item, "coin", true);
            }
          });
          this.formData.sendNum = 0;
          this.getExchangePrice(true);
          this.$scrollToTop();
        }
      },
    },
    "formData.address"() {
      if (this.formData.address.length > 30) {
        this.validAddress();
      }
    },
  },
  methods: {
    getNotice() {
      var ts_last = this.$cookies.get("notice_ts");
      var ts = parseInt(new Date().getTime() / 1000);
      if (!ts_last) {
        this.$cookies.set("notice_ts", ts);
      } else {
        if (ts - ts_last < 3600 * 24) {
          return;
        } else {
          this.$cookies.set("notice_ts", ts);
        }
      }
      var lang_index = this.$cookies.get("langIndex");
      this.$api.getNotice().then((res) => {
        if (res.data.show) {
          this.$modal.show("dialog", {
            title: res.data.content[lang_index][0],
            text:
              res.data.content[lang_index][1] +
              res.data.scam_list.join("<br />"),
            buttons: [
              {
                title: res.data.content[lang_index][2],
                handler: () => {
                  this.$modal.hide("dialog");
                },
              },
            ],
          });
        }
      });
    },
    getCurrencyList() {
      this.$api.getCurrencyList().then((res) => {
        let currencyList = res.data;
        for (let key in currencyList) {
          currencyList[key].forEach((item) => {
            item[2] = item[2][1];
          });
        }
        this.currencyList = currencyList;
        this.chooseCoin("leftCoin", this.currencyList.popular[3], "coin", true);
        this.chooseCoin(
          "rightCoin",
          this.currencyList.popular[4],
          "coin",
          true
        );
        this.getExchangePrice(true);
      });
    },
    getExchangePrice(isFresh = false) {
      this.$api
        .getExchangePrice({
          from_currency: this.leftCoin.sortName,
          to_currency: this.rightCoin.sortName,
          from_qty: this.formData.sendNum,
          type: this.formData.type,
          // to_qty: "",
        })
        .then((res) => {
          this.exchangePrice = res.data;
          this.formData.receiveNum = "≈" + res.data.to.amount;
          if (isFresh || this.formData.sendNum == 0) {
            this.formData.sendNum = this.exchangePrice.from.suggest;
            this.formData.receiveNum = "≈" + res.data.to.suggest;
            this.exchangePrice.from.rate = this.exchangePrice.from.rate_suggest;
            this.exchangePrice.to.rate = this.exchangePrice.to.rate_suggest;
            this.exchangePrice.usd = this.exchangePrice.usd_suggest;
          }
          if (this.formData.sendNum < this.exchangePrice.from.min) {
            this.formData.sendNum = this.exchangePrice.from.min;
          }
        });
    },
    getExchangePriceByToQty(to_qty) {
      this.$api
        .getExchangePrice({
          from_currency: this.leftCoin.sortName,
          to_currency: this.rightCoin.sortName,
          type: this.formData.type,
          to_qty,
        })
        .then((res) => {
          this.exchangePrice = res.data;
          this.formData.receiveNum = "≈" + res.data.to.amount;
          this.formData.sendNum = res.data.from.amount;
        });
    },
    validAddress() {
      this.hadvalid = false;
      this.isvalidAddress = false;
      if (this.formData.address == "") {
        return;
      }
      this.$api
        .validAddress({
          currency: this.rightCoin.sortName,
          address: this.formData.address,
        })
        .then((res) => {
          this.hadvalid = true;
          this.isvalidAddress = res.data.valid;
        });
    },
    chooseCoin(key, item, type, isDefault = false) {
      this.searchText = "";
      if (
        (key == "leftCoin" && this.rightCoin.sortName === item[1]) ||
        (key == "rightCoin" && this.leftCoin.sortName === item[1])
      ) {
        this.showLeftSelet = false;
        this.showRightSelet = false;
        this.exchangeCoin();
        return;
      }
      this[key] = {
        type,
        name: item[0],
        sortName: item[1],
        iconName: item[2],
      };
      this.showLeftSelet = false;
      this.showRightSelet = false;
      this.formData.sendNum = 0;
      if (!isDefault) {
        this.getExchangePrice();
      }
      this.validAddress();
    },
    changeSendNum() {
      if (this.formData.sendNum < this.exchangePrice.from.min - 0) {
        this.formData.sendNum = this.exchangePrice.from.min;
        return;
      }
      this.getExchangePrice();
    },
    getExchangeMake() {
      if (!this.isvalidAddress || this.readyToOrder) {
        return;
      }
      this.readyToOrder = true;
      this.$api
        .getExchangeMake({
          from_currency: this.leftCoin.sortName,
          to_currency: this.rightCoin.sortName,
          from_qty: this.formData.sendNum,
          type: this.formData.type,
          toAddress: this.formData.address,
        })
        .then((res) => {
          this.$router.push({
            path: "/main/order",
            query: { orderId: res.data.order_id },
          });
        })
        .finally(() => {
          this.readyToOrder = false;
        });
    },
    exchangeCoin() {
      let temporary = { ...this.leftCoin };
      this.leftCoin = { ...this.rightCoin };
      this.rightCoin = { ...temporary };
      this.formData.sendNum = this.formData.receiveNum.replace("≈", "") - 0;
      this.getExchangePrice();
      this.validAddress();
    },
  },
  computed: {
    currencyListPopularSearch: function () {
      let arr = [];
      this.currencyList.popular.forEach((item) => {
        if (
          item[0].match(new RegExp(this.searchText, "ig")) ||
          item[1].match(new RegExp(this.searchText, "ig"))
        ) {
          arr.push(item);
        }
      });
      return arr;
    },
    currencyListCoinSearch: function () {
      let arr = [];
      this.currencyList.coin.forEach((item) => {
        if (
          item[0].match(new RegExp(this.searchText, "ig")) ||
          item[1].match(new RegExp(this.searchText, "ig"))
        ) {
          arr.push(item);
        }
      });
      return arr;
    },
    currencyListTokenSearch: function () {
      let arr = [];
      this.currencyList.token.forEach((item) => {
        if (
          item[0].match(new RegExp(this.searchText, "ig")) ||
          item[1].match(new RegExp(this.searchText, "ig"))
        ) {
          arr.push(item);
        }
      });
      return arr;
    },
  },
};
</script>

<style lang="less" scoped>
@import url("../../../assets/css/home.less");
.self-scorll {
  &::-webkit-scrollbar {
    width: 5px;
  }
  /*滚动条里面小方块*/
  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 15px;
  }
  /*滚动条里面轨道*/
  &::-webkit-scrollbar-track {
    border-radius: 0;
    background-color: rgba(255, 255, 255, 0.05);
  }
}
.my-faq > li > p {
    line-height: 22px;
    // word-spacing: 1px;
}
.mobile > div  > p{
color: rgba(140, 145, 175, 1) !important;
}
</style>